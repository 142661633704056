var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "green-border-box in-padd white-bg" },
    [
      _c("OfferPaymentPopup", {
        attrs: { brandOffer: _vm.brandOffer },
        on: { paymentSuccessfull: _vm.setOfferAsRedeemed }
      }),
      _c(
        "button",
        {
          ref: "showBranfOfferPaymentPopup",
          staticStyle: { display: "none" },
          attrs: {
            "data-target": "#redeem-offer-popup",
            "data-toggle": "modal"
          }
        },
        [_vm._v("o")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }