







import OfferItem from '@/components/Brand/OfferItem.vue'
import OfferPaymentPopup from '@/components/Brand/OfferPaymentPopup.vue'

import Vue from 'vue'
import Component from 'vue-class-component'
@Component({
  components: {
    OfferItem,
    OfferPaymentPopup
  }
})
export default class Countries extends Vue {
  public brandOffer: any = {
    offer: {
      name: '',
      credits: 0,
      price: 0
    }
  }

  fetchOfferBySecret () {
    let payload = {
      brandId: this.$store.state.userData.brandId,
      secret: this.$route.query.secret,
      startDate: new Date()
    }
    this.$store.dispatch('fetchOfferBySecret', payload).then((response) => {
      if (response.body.id === null) {
        this.$notify({ type: 'error', text: 'This offer is already assigned to you, please check offers tab from sidebar!' })
      } else {
        this.brandOffer = response.body
        let showBranfOfferPaymentPopup: HTMLElement = this.$refs.showBranfOfferPaymentPopup as HTMLElement
        showBranfOfferPaymentPopup.click()
      }
    }, (response) => {
    })
  }

  setOfferAsRedeemed (token:any) {
    this.$store.commit('showLoading')
    let payload = {
      token: token.id,
      brandId: this.$store.state.userData.brandId,
      brandOfferId: this.brandOffer.id,
      offerId: this.brandOffer.offer.id
    }
    this.$http
      .post(process.env.VUE_APP_API_BASE_URL + `/brands/${payload.brandId}/offers/redeem`, payload)
      .then((response:any) => {
        if (response.status === 200) {
          this.$router.push('/brand/dashboard')
          this.$store.commit('showMessage', { message: 'Transaction<br /> Successful!' })
        } else {
          this.$notify({ type: 'error', text: 'Internal server error!' })
          this.$store.commit('hideMessage')
        }
      })
  }
  mounted () {
    this.fetchOfferBySecret()
  }
}
